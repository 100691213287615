import React from "react";
import Check from "../../images/check.svg";
import { useLanguage } from "../../languageContext";

const GokenGoImpact = ({ imapactItems }) => {
  const currentLanguage = useLanguage();
  return (
    <div className="section m-top-10">
      <div className="container">
        <div className="gokengo-impact-section">
          <div
            className={`money-text-wrapper bg-${
              currentLanguage ? currentLanguage : "en"
            }`}
          >
            <p className="heading title">$250,000</p>
            <span className="semi-para">
              <i>saved annually</i>
            </span>
          </div>
          <h2 className="heading m-top-20px">
            Impact of workflow optimisation
          </h2>
          <p className="tagline para m-top-10px">
            a simple tool to aggregate shift-wise information helped one of our
            clients create huge economic impact
          </p>
          <div className="impacts-content-wrapper m-top-20px">
            {imapactItems &&
              imapactItems.map(({ heading, paragraph, icons }) => (
                <div className="impact-item">
                  <img
                    src={
                      icons && icons.length > 0
                        ? currentLanguage === "en"
                          ? icons[0].url
                          : currentLanguage === "in"
                          ? icons[1].url
                          : currentLanguage === "ja"
                          ? icons[2].url
                          : icons[0].url
                        : ""
                    }
                    alt="Check Logo"
                  ></img>
                  <h3 className="heading semi-title">{heading}</h3>
                  <p className="para m-top-10px">{paragraph} </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GokenGoImpact;
