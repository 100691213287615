import React, { useEffect, useRef } from "react";
import { gsap, Power3 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { useLanguage } from "../../languageContext";

import IconScrollen from "../../images/solutionsassets/iconScroll-en.svg";
import IconScrollin from "../../images/solutionsassets/iconScroll-in.svg";
import IconScrollja from "../../images/solutionsassets/iconScroll-ja.svg";

const GokengoSlider = ({ paragraph, sliderItems }) => {
  let RightScroll = useRef(null);
  let myElement = undefined;

  let sections = gsap.utils.toArray(myElement);

  useEffect(() => {
    try {
      gsap.registerPlugin(ScrollTrigger);
      ScrollTrigger.matchMedia({
        "(min-width:1024px)": function () {
          myElement = document.querySelectorAll(".gokengo-slider-item");
          // true

          const tl = gsap.timeline({
            // paused: true,
            scrollTrigger: {
              trigger: ".gokengo-slider-container",
              scrub: true,
              invalidateOnRefresh: true,
              start: "-=100px",
              endTrigger: ".banner-wrapper",
              // end: () => "+=" + (document.querySelector(".slider-wrapper").offsetWidth)/4,
              pin: true,
              pinSpacing: "false",
            },
          });
          // mobile

          tl.to(RightScroll, {
            x: () =>
              "-=" +
              document.querySelector(".slider-wrapper").offsetWidth / 1.6,
            duration: 4,
            ease: Power3.easeOut,
          });
        },
      });

      return () => {
        let triggers = ScrollTrigger.getAll();
        triggers.forEach((trigger) => {
          trigger.kill();
        });
      };
    } catch (error) {
      console.log("removing error ", error);
    }
  }, []);

  const currentLanguage = useLanguage();
  return (
    <div className="section">
      <div className="gokengo-slider-container container">
        <div className="gokengo-slider-wrapper m-top-20px">
          <div className="slider-wrapper" ref={(el) => (RightScroll = el)}>
            <div className="content">
              <h2 className="heading">Our platform brings it all together</h2>
              {paragraph ? <p className="para m-top-10px">{paragraph}</p> : ""}
              <div
                className={`tagline color-${
                  currentLanguage ? currentLanguage : "en"
                } m-top-10px`}
              >
                Page will scroll this way{" "}
                {currentLanguage === "en" ? (
                  <img src={IconScrollen} alt="Icon Scroll"></img>
                ) : currentLanguage === "in" ? (
                  <img src={IconScrollin} alt="Icon Scroll"></img>
                ) : currentLanguage === "ja" ? (
                  <img src={IconScrollja} alt="Icon Scroll"></img>
                ) : (
                  <img src={IconScrollen} alt="Icon Scroll"></img>
                )}
              </div>
            </div>
            {sliderItems &&
              sliderItems.map(({ heading, paragraph }, index) => {
                return (
                  <div
                    key={index}
                    className={`gokengo-slider-item bg-image-gokengo-item-${
                      index + 1
                    } border-bottom-${
                      currentLanguage ? currentLanguage : "en"
                    }`}
                  >
                    <div className="info-wrapper">
                      <h2 className="gokengo-card-heading semi-title">
                        {heading}
                      </h2>
                      <p className="para">{paragraph}</p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="mobile-platform-detais mobile-container">
            <div className="content">
              <div className="heading">OUR PLATFORM BRINGS IT ALL TOGETHER</div>
              <div className="para m-top-20px" style={{ color: "black" }}>
                GokenGO platform can enable manufacturing factory floors,
                process plants, Oil & Gas sites and hospital ICUs to go digital.
                It captures the essence of continuity in daily operations.
              </div>
            </div>
            <div className="element-wrapper">
              {sliderItems &&
                sliderItems.map(({ heading, paragraph }, index) => (
                  <div
                    key={index}
                    className={`gokengo-slider-item bg-image-gokengo-item-${
                      index + 1
                    } border-bottom-${
                      currentLanguage ? currentLanguage : "en"
                    }`}
                  >
                    <div className="info-wrapper">
                      <div className="heading semi-title">{heading}</div>
                      <div className="card-text m-top-10px">{paragraph}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GokengoSlider;
