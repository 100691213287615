import React, { useState } from "react";
import ImageBanner from "../../components/atoms/banner/imageBanner";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import GokengoHeader from "../../molecules/gokengo/GokengoHeader";
import GokenGoImpact from "../../molecules/gokengo/GokenGoImpact";
import GokenGoMissingLink from "../../molecules/gokengo/GokenGoMissingLink";
import GokengoSlider from "../../molecules/gokengo/GokengoSlider";
import ContactForm from "../../molecules/ContactForm";
import SideDrawer from "../../molecules/SideDrawer";

import Fade from "react-reveal/Fade";

import { useStaticQuery, graphql } from "gatsby";
import HeaderWithVideo from "../../molecules/HeaderWithVideo";

// import NewGokenGoVideo from "../../../src/videos/GokenGO-with-subtitles.mp4";
import StaffingDescription from "../../molecules/staffing/staffingDescription";
import GokenGoDescription from "../../molecules/gokengo/GokenGoDescription";

import AboutGokenGo from "../../images/gokengoassets/aboutgokengo.png";
// import AboutGokenGo from "../ima";

const GokenGO = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const closeDrawer = () => {
    setSideDrawerOpen(false);
  };

  const openDrawer = () => {
    setSideDrawerOpen(true);
  };

  const data = useStaticQuery(graphql`
    query GokenGoData {
      gcms {
        gokenGo(where: { id: "ckgmlfa2o2ose0a701eyhml73" }) {
          heading
          headerVideo {
            url
          }
          scrollParagraph
          gokenGoSliderItems {
            heading
            paragraph
          }
          gokenGoTechnicalItems {
            heading
            paragraph
            icons {
              url
            }
          }
          missingLinkItemOneHeading
          missingLinkItemOneParagraph
          missingLinkItemThreeHeading
          missingLinkItemThreeParagraph
          missingLinkItemTwoHeading
          missingLinkItemTwoParagraph
        }
      }
    }
  `);
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/services/gokengo/"}
        title={"GokenGo Services: GokenGo - Mobility by Goken"}
        description={
          "Experience the future of mobility with GokenGo by Goken. Innovative, sustainable solutions for a moving world. Click to explore our services!"
        }
        // meta="GokenGO, GokenGO Product, shop floor productivity, shift handover, daily work management, production metrics, operations management"
        facebookDescription={
          "Goken Manufacturing Operations Management Application is a 24-hour operations management platform for the manufacturing factory floor which helps to increase productivity and reduce downtime of your assets."
        }
        facebookTitle={
          "Goken | Goken Go | Manufacturing Operations Management Application"
        }
        keywords={
          "data analytics, 24 hours operations, asset monitoring platform, cost saving, digitization, digitalisation, manufacturing productivity"
        }
      ></SEO>
      <SideDrawer isActive={sideDrawerOpen} closeAction={closeDrawer}>
        <div className="goken-go-side-wrapper">
          <Fade bottom>
            <h2 className="heading">About GokenGO</h2>
          </Fade>
          <div className="para m-top-20px">
            <p>
              Until 2019, several industries regularly used data analytics to
              publish static reports and interactive dashboards. Covid-19
              pandemic in 2020 changed how the world does business and demanded
              process changes. Post Covid-19 industry will be characterized by
              remote work, staggered workforce, social distancing and
              traceability of people and material.
              <br></br>
              GokenGO, a 24 hour operations platform was developed during the
              pandemic to ensure traceability of daily work, issues and people;
              while mapping your existing plants, lines and departments on our
              platform. Now you can safely handover your work and review your
              daily metrics without having to bring the entire team together in
              your control room.
            </p>
          </div>
          <div className="image-wrapper m-top-20px">
            <img
              src="https://images.unsplash.com/photo-1485083269755-a7b559a4fe5e?auto=format&fit=crop&ixid=eyJhcHBfaWQiOjEyMDd9&ixlib=rb-1.2.1&q=80&w=1500"
              alt="About Goken Go"
            />
          </div>
        </div>
      </SideDrawer>

      <HeaderWithVideo
        // preHeading={"WITNESS HUGE SAVINGS WITH GOKENGO"}
        headingOne="GokenGO"
        para="A 24 hours operations monitoring platform powered by the built-in Shift Handover Process, that enables our clients huge cost savings."
        btnTxt="Learn How"
        url="/contactus/"
        video={data.gcms.gokenGo.headerVideo}
      />
      {/* <GokengoHeader
        openDrawer={openDrawer}
        heading={data.gcms.gokenGo.heading}
      ></GokengoHeader> */}

      <GokenGoDescription
        headingOne="About GokenGo"
        description="Until 2019, several industries regularly used data analytics to publish static reports and interactive dashboards. Covid-19 pandemic in 2020 changed how the world does business and demanded process changes. Post Covid-19 industry will be characterized by remote work, staggered workforce, social distancing and traceability of people and material.
        GokenGO, a 24 hour operations platform was developed during the pandemic to ensure traceability of daily work, issues and people; while mapping your existing plants, lines and departments on our platform. Now you can safely handover your work and review your daily metrics without having to bring the entire team together in your control room.
        Until 2019, several industries regularly used data analytics to
       publish static reports and interactive dashboards. Covid-19
       pandemic in 2020 changed how the world does business and demanded
       process changes. Post Covid-19 industry will be characterized by
       remote work, staggered workforce, social distancing and
       traceability of people and material. "
        //   descriptionTwo="GokenGO, a 24 hour operations platform was developed during the
        //  pandemic to ensure traceability of daily work, issues and people;
        //  while mapping your existing plants, lines and departments on our
        //  platform. Now you can safely handover your work and review your
        //  daily metrics without having to bring the entire team together in
        //  your control room."
        image={AboutGokenGo}
      />

      <GokengoSlider
        paragraph={data.gcms.gokenGo.scrollParagraph}
        sliderItems={data.gcms.gokenGo.gokenGoSliderItems}
      ></GokengoSlider>

      <GokenGoImpact
        imapactItems={data.gcms.gokenGo.gokenGoTechnicalItems}
      ></GokenGoImpact>
      <GokenGoMissingLink
        mainHeadingOne="The missing link in your daily"
        mainHeadingTwo="work management"
        mainParaOne="We will not just provide the tools to get the job done, we will help"
        mainParaTwo="you with the process and the results are there for you to see!"
        headingOne={data.gcms.gokenGo.missingLinkItemOneHeading}
        headingTwo={data.gcms.gokenGo.missingLinkItemTwoHeading}
        headingThree={data.gcms.gokenGo.missingLinkItemThreeHeading}
        paragraphOne={data.gcms.gokenGo.missingLinkItemOneParagraph}
        paragraphTwo={data.gcms.gokenGo.missingLinkItemTwoParagraph}
        paragraphThree={data.gcms.gokenGo.missingLinkItemThreeParagraph}
      ></GokenGoMissingLink>
      {/* <ContactForm
        showForm={true}
        typeOfForm="Product or Service Inquiry"
      ></ContactForm> */}
      <ImageBanner
        heading="Interested to learn more about GokenGO?"
        btnText="Request A Demo"
        link="/contactus/"
      ></ImageBanner>
    </Layout>
  );
};

export default GokenGO;
