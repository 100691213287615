import React from "react";

import AlertOctagon from "../../images/gokengoassets/alert-octagon.svg";
import Database from "../../images/gokengoassets/database.svg";
import pieChart from "../../images/gokengoassets/pie-chart.svg";

const GokenGoMissingLink = ({
  mainHeadingOne,
  mainHeadingTwo,
  mainParaOne,
  mainParaTwo,
  headingOne,
  paragraphOne,
  headingTwo,
  paragraphTwo,
  headingThree,
  paragraphThree,
}) => {
  return (
    <div className="section m-top-20px bg-light-blue">
      <div className="container">
        <div className="missinglink-section">
          <h2 className="heading">
            {mainHeadingOne} <br></br> {mainHeadingTwo}
          </h2>
          <p className="tagline para m-top-10px">
            {mainParaOne} {mainParaTwo}
          </p>
          <div className="missinglink-items m-top-10">
            <div className="missinglink-item">
              <img src={Database} alt="Missing Link Icon"></img>
              <h3 className="heading semi-title m-top-20px">{headingOne}</h3>
              <p className="para m-top-10px">{paragraphOne}</p>
            </div>
            <div className="missinglink-item">
              <img src={AlertOctagon} alt="Missing Link Icon"></img>
              <h3 className="heading semi-title m-top-20px">{headingTwo}</h3>
              <p className="para m-top-10px">{paragraphTwo}</p>
            </div>
            <div className="missinglink-item">
              <img src={pieChart} alt="Missing Link Icon"></img>
              <h3 className="heading semi-title m-top-20px">{headingThree}</h3>
              <p className="para m-top-10px">{paragraphThree}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GokenGoMissingLink;
