import React from "react";
import BackBtn from "../images/back.svg";
import Slide from "react-reveal/Slide";

const SideDrawer = ({ children, closeAction, isActive }) => {
  return (
    <>
      {isActive ? (
        <div className="sidemodal-wrapper">
          <Slide left>
            <div onClick={closeAction} className="blur-section"></div>
          </Slide>
          <Slide right>
            <div className="content-about">
              <div className="back-button">
                <img onClick={closeAction} src={BackBtn} alt="Back Button"></img>
                <button onClick={closeAction}>Go Back</button>
              </div>
              <div className="content-about-section">{children}</div>
            </div>
          </Slide>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SideDrawer;
